
import { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

// import MenuItem from './components/Menu';
// import Homepage from './pages/Homepage';
// import Players from './pages/Players';
// import Work from './pages/Work';
// import Login from './pages/Login';
import Protected from './components/Protected';
// import WorkAdmin from './pages/WorkAdmin';
import ScrollToTop from './ScrollToTop';
import BackgroundOverlay from './components/BackgroundOverlay';

const MenuItem = lazy(() => import('./components/Menu'));
const Homepage = lazy(() => import('./pages/Homepage'));
const Work = lazy(() => import('./pages/Work'));
const Login = lazy(() => import('./pages/Login'));
const WorkAdmin = lazy(() => import('./pages/WorkAdmin'));

function App() {
  const [token, setToken] = useState(localStorage.getItem("tkn") === null ? null : localStorage.getItem("tkn"))

  useEffect(() => {
    localStorage.setItem("tkn", token)

  }, [token])

  const [active, setActive] = useState("1");

  return (

    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <BackgroundOverlay />

        <MenuItem active={active} setActive={setActive} setToken={setToken} />

        <Suspense
          fallback={
            <div className='loaderWrapper'>
              <span className="loader"></span>
            </div>
          }>
          <Routes>
            <Route path="/" element={<Homepage active={active} setActive={setActive} />} />
            <Route path="/work" element={<Work />} />

            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route path='/admin'
              element={
                <Protected>
                  <WorkAdmin setToken={setToken} />
                </Protected>
              }
            />
          </Routes>
        </Suspense>

      </BrowserRouter>
    </div>
  );
}

export default App;
