import React from 'react'
import styled from 'styled-components'



const Wrapper = styled.div`
    position: fixed; 
    z-index: -1;
    background-color: #F1F1F1;
    width: 100%;
    height: 100%;
`
function BackgroundOverlay() {
  return (
    <Wrapper>
        
    </Wrapper>
  )
}

export default BackgroundOverlay